import React from "react";
import { Button } from "flowbite-react";
import { useNavigate } from "react-router-dom";

const PromotedInfo = ({ data, selectedType }) => {
  const navigate = useNavigate();

  const handleRoute = (item) => {
    const { type, jobId, productId, projectId, postId, serviceId } = item;

    switch (type) {
      case "PRODUCT":
        navigate(
          `/findproducts/products_for_you_/product_detail_/${productId}`
        );
        break;
      case "SERVICE":
        navigate(`/ListProject/Services_Seller/Service_Details/${serviceId}`);
        break;
      case "JOB":
        navigate(`/findjobs/Job_Buyer/Job_details/${jobId}`);
        break;
      case "PROJECT":
        navigate(`/marketTrend/Other_Project/Project_detail/${projectId}`);
        break;
      case "POST":
        navigate(`/post/${postId}`);
        break;
      default:
        // Handle other types or provide a default route
        break;
    }
  };

  return (
    <>

      <div className="overflow-auto">
        <table className="min-w-full">
       

          <tbody>
            {data
              .filter((category) => category.type === selectedType)
              .map((category, index) => (
                <tr
                  // key={category._id}
                  className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
                >
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {index + 1}-
                  </td>

                  <td className="text-sm   font-all font-semibold px-6 py-4 whitespace-nowrap">
                    {category.type}
                  </td>

                  <td className=" text-[17px]  font-all font-bold px-6 py-4 whitespace-nowrap">
                    {category.bidAmount}{" "}
                    <span className="font-light text-sm">inr</span>
                  </td>

                  <td className=" text-[17px]  font-all font-bold px-6 py-4 whitespace-nowrap">
                    {category.duration}
                  </td>

                  <td className=" text-[17px]  font-all font-bold px-6 py-4 whitespace-nowrap">
                    {category.clicks}
                  </td>

                  <td className=" text-xs  font-all font-light px-6 py-4 whitespace-nowrap">
                    {new Date(category.createdAt).toLocaleString("en-US", {
                      weekday: "long",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      second: "numeric",
                      timeZoneName: "short",
                    })}
                  </td>

                  <td className=" flex flex-row gap-3 text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                    <Button
                      onClick={() => handleRoute(category)}
                      className="bg-black text-white"
                    >
                      View promotion info
                    </Button>

                    <Button
                      onClick={() => handleRoute(category)}
                      className="bg-red-600 text-white"
                    >
                      view module info
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default PromotedInfo;
