// App.js

import React from 'react';
import Routes from './Routes';
import { WalletProvider } from '../src/pages/Wallet/Context/WalletContext';
import CountrySelect from './test/CountrySelect';

function App() {
  return (
    <>

      <WalletProvider>
        {/* <CountrySelect/> */}
        <Routes />
      </WalletProvider>
    </>
  );
}

export default App;
