import { useEffect, useState } from "react";
import { registerUserdata } from "../../../../api/userRegestration/CollectionForm";

function EditProfile({ togglemode, profileDetails,view }) {
  //
  const [formData, setformData] = useState({
    name: profileDetails.name,
    email: profileDetails.email,
    bio: profileDetails.bio,
    mobileNumber: profileDetails.mobileNumber,
  });

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const userData = 
      {
    //     "name": "hola",
    //     "userName": "Ahmad",
    //     "email":  formData.email,
    //    "gender":"MAlE",
    //    "bio":"Engineer",
    //     "dob":"Nothing",
    //    "facebook":"https://www.fac00&creative=bookmark",
    //     "twitter":"https://twitter.com/",
    //     "instagram":"https://instagram.com/",
    //     "linkedIn":"https://www.linkedin.com/signup/cold-join?session_redirect=https%3A%2F%2Fwww%2Elinkedin%2Ecom%2Ffeed%2F&trk=login_reg_redirect",
    //     "location":"https://www.google.com/maps/place/Pakistan/@30.3593034,68.9966984,6z/data=!3m1!4b1!4m6!3m5!1s0x38db52d2f8fd751f:0x46b7a1f7e614925c!8m2!3d30.375321!4d69.345116!16zL20vMDVzYjE?entry=ttu",
    //     "profilePic":"https://media.sproutsocial.com/uploads/2022/06/profile-picture.jpeg",
    //     "coverPic":"https://media.sproutsocial.com/uploads/2022/06/profile-picture.jpeg"
        
    
    
    
    }

      const registeredUser = await registerUserdata(userData);
      console.log("Registered User:", registeredUser);

    } catch (error) {}
  };


  const handleInputChange = (e) => {
    const { name, value, type } = e.target;

    setformData((prevData) => ({
      ...prevData,
      [name]: type === "text" ? value : prevData[name],
    }));
  };

  //
  return (
    <>
      <div className="bg-layout shadow-md drop-shadow-md lg:w-[155vh] mb-[7rem] lg:ml-[268px] relative top-[1rem] h-max">
        <h1 className="fade-in-left font-all text-center font-semibold lg:text-[22px] lg:mt-[2rem] mb-8">
          Edit Update Profile
        </h1>
        <>
          <div className="min-h-screen  flex flex-col justify-start fade-in-right">
            <div className="relative py-3 sm:max-w-xl sm:mx-auto">
              <div className="relative px-4  bg-white mx-8 md:mx-0 shadow rounded-3xl sm:p-10">
                <div className="max-w-md mx-auto">
                  <form  onSubmit={handleSubmit} className="divide-y divide-gray-200">
                    <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                      <div className="flex flex-col">
                        <label className="leading-loose">name</label>
                        <input
                          type="text"
                          className="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
                          name="name"
                         value={formData.name}
                      onChange={handleInputChange}

                        />
                      </div>

                      <div className="flex flex-col">
                        <label className="leading-loose">
                          Aboutyourself / Bio
                        </label>
                        <input
                          type="text"
                          className="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
                          name="bio"
                          value={formData.bio}
                      onChange={handleInputChange}

                        />
                      </div>
                      <div className="flex items-center space-x-4">
                        <div className="flex flex-col">
                          <label className="leading-loose">email</label>
                          <div className="relative focus-within:text-gray-600 text-gray-400">
                            <input
                              type="text"
                              className="pr-4 pl-10 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
                              name="email"
                          value={formData.email}
                          onChange={handleInputChange}

                            />
                          </div>
                        </div>
                        <div className="flex flex-col">
                          <label className="leading-loose">phone Number</label>
                          <div className="relative focus-within:text-gray-600 text-gray-400">
                            <input
                              type="text"
                              className="pr-4 pl-10 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
                              name="mobileNumber"
                           value={formData.mobileNumber}
                      onChange={handleInputChange}

                            />
                          </div>
                        </div>
                      </div>
           
                    </div>
                    <div className="pt-4 flex items-center space-x-4">
                      <button
                        onClick={()=>{
                          togglemode()
                          view()

                        }}
                        className="flex justify-center items-center w-full text-gray-900 px-4 py-3 rounded-md focus:outline-none"
                      >
                        <svg
                          className="w-6 h-6 mr-3"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>{" "}
                        Cancel
                      </button>
                      <button type="submit" className="bg-[#cb2229] flex justify-center items-center w-full text-white px-4 py-3 rounded-md focus:outline-none">
                        Update Profile
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    </>
  );
}

export default EditProfile;
