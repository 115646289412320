import React, { useState } from "react";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { Link } from "react-router-dom";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { Rating } from "flowbite-react";

function ProductHomeslider() {
  const carouselData = [
    {
      name: "Apple",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTko7wO6ZZQX-qxqf4xATkFaRkF5sVwZZC-K4Zn_f4NhrLPJr2Z1ToLsmwntHW2c3mF-UI&usqp=CAU",
    },
    {
      name: "samsung",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTko7wO6ZZQX-qxqf4xATkFaRkF5sVwZZC-K4Zn_f4NhrLPJr2Z1ToLsmwntHW2c3mF-UI&usqp=CAU",
    },
    {
      name: "lenovo",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTko7wO6ZZQX-qxqf4xATkFaRkF5sVwZZC-K4Zn_f4NhrLPJr2Z1ToLsmwntHW2c3mF-UI&usqp=CAU",
    },
    {
      name: "mi",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTko7wO6ZZQX-qxqf4xATkFaRkF5sVwZZC-K4Zn_f4NhrLPJr2Z1ToLsmwntHW2c3mF-UI&usqp=CAU",
    },
    {
      name: "poco",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTko7wO6ZZQX-qxqf4xATkFaRkF5sVwZZC-K4Zn_f4NhrLPJr2Z1ToLsmwntHW2c3mF-UI&usqp=CAU",
    },
    {
      name: "realme",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTko7wO6ZZQX-qxqf4xATkFaRkF5sVwZZC-K4Zn_f4NhrLPJr2Z1ToLsmwntHW2c3mF-UI&usqp=CAU",
    },
    {
      name: "realme",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTko7wO6ZZQX-qxqf4xATkFaRkF5sVwZZC-K4Zn_f4NhrLPJr2Z1ToLsmwntHW2c3mF-UI&usqp=CAU",
    },
    {
      name: "realme",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTko7wO6ZZQX-qxqf4xATkFaRkF5sVwZZC-K4Zn_f4NhrLPJr2Z1ToLsmwntHW2c3mF-UI&usqp=CAU",
    },
    {
      name: "realme",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTko7wO6ZZQX-qxqf4xATkFaRkF5sVwZZC-K4Zn_f4NhrLPJr2Z1ToLsmwntHW2c3mF-UI&usqp=CAU",
    },
    {
      name: "realme",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTko7wO6ZZQX-qxqf4xATkFaRkF5sVwZZC-K4Zn_f4NhrLPJr2Z1ToLsmwntHW2c3mF-UI&usqp=CAU",
    },
  ];
  const scrollLeft = () => {
    document.getElementById("content").scrollLeft -= 400;
  };
  const scrollRight = () => {
    document.getElementById("content").scrollLeft += 400;
  };
  const [showIcon1, setShowIcon1] = useState(true);

  const toggleIcon = () => {
    setShowIcon1(!showIcon1);
  };

  return (
    <>
      <div className="relative">
        <h1 className="text-center font-all font-extrabold text-[#263238] lg:text-[19px]">
          Products For You
        </h1>

        <div className="absolute right-0 lg:top-[3.2rem] z-10 xs:top-[20px]">
          <button
            onClick={scrollLeft}
            className="relative lg:top-[77px] bg-[#0000002e] lg:h-[3rem] p-2 m-2 rounded-md lg:right-[67.5rem] carousel-bg"
          >
            <FiChevronLeft className="relative lg:right-[1px] text-black" />
          </button>
          <button
            onClick={scrollRight}
            className=" relative lg:top-[77px] bg-[#0000002e] lg:h-[3rem] p-2 m-2 rounded-md lg:left-[65px]"
          >
            <FiChevronRight className="relative text-black" />
          </button>
        </div>
        <div
          id="content"
          className="carousel p-4 flex items-center justify-start overflow-x-auto scroll-smooth scrollbar-hide lg:h-[auto] -m-4 mt-1"
        >
          {carouselData.map((item) => (
            <div className="carousel-card lg:ml-[-18px]" key={item.name}>
              <div className="p-4 lg:w-[246px] xs:w-[14rem] drop-shadow-xl">
                <div className="bg-white p-[5px] absolute z-10 rounded-full	right-[31px] top-[22px] cursor-pointer">
                  {showIcon1 ? (
                    <AiOutlineHeart onClick={toggleIcon} />
                  ) : (
                    <AiFillHeart
                      className="text-red-400"
                      onClick={toggleIcon}
                    />
                  )}
                </div>

                <div className="lg:h-[260px] lg:w-[202px]   shadow-inner pb-24 rounded-lg overflow-hidden text-center relative intrest-card bg-[#ffff] h-[137px]  ">
                  {/* <Link to="ProductPage"> */}
                    <img
                      src={item.image}
                      className="lg:h-[142px] l:w-[242px]"
                      alt=""
                    />
                  {/* </Link> */}

                  <div className="flex flex-row items-center justify-center gap-[15px]">
                    <h5 className="text-black font-all font-bold lg:mt-[9px]">
                      {item.name}
                    </h5>

                    <Rating className="relative top-[3px]">
                      <Rating.Star className="text-yellow-300 text-[10px] " />
                      <Rating.Star className="text-yellow-300 text-[10px] " />
                      <Rating.Star />
                      <p className="ml-2  font-medium text-gray-500 dark:text-gray-400 text-[10px] ">
                        4.95 /5
                      </p>
                    </Rating>
                  </div>

                  <p className="font-extra-light text-gray-500 italic text-[11px] mt-[7px]">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Numquam, natus nihil. Aspernatur.
                  </p>

                  <div className="flex flex-row items-center justify-center gap-[5rem] ">
                    <div className="flex flex-row item-1 gap-[7px]">
                      <p className=" text-gray-500 italic text-[11px]">MOQ:4</p>{" "}
                      <p className=" text-gray-500 italic text-[11px]">MQ:4</p>
                    </div>

                    <div className="item-b item-end">
                      <span style={{ color: "red" }}>$67</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default ProductHomeslider;
