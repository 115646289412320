import React, { useState } from "react";
import Logo from "../../../../assets/logo/Logo-Trans.png";
import image from "../../../../assets/logo/auth.png";
import { useNavigate } from "react-router-dom";
import {BecomeReseller} from "../../../../api/userRegestration/CollectionForm"
import axios from "axios";

export default function ResellerForm() {
  const navigate = useNavigate();


  const [email, setemail] = useState("");
  const handleMobileNumberChange = (e) => {
    if (e && e.target) {
      setemail(e.target.value);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
   
      // Include the token in the request headers
      const token = {
        headers: {
           token : localStorage.getItem("token") ,
        },
      };
  
      // Send the POST request with the token
      const response = await axios.post(

        
        "https://inobackend-production.up.railway.app/api/v1/user/sendOtpToMail",
        {
          email: email,
        },
        token 
      );
  
      console.log(response.data);
   
  
      const url = `/register/resellerVerification/:${email}`;
      navigate(url);
    } catch (error) {
      console.error("Error registering user:", error);
    
    }
  };
  

  return (
    <>
       <div className="flex flex-row  bg-[#cb22281c]">
          <div className=" xs:hidden  item-1 h-screen lg:w-[100vh]">
            <div className="flex flex-col justify-center items-center mt-12 gap-[12pxs]">
              <img
                src={Logo}
                alt=""
                className="lg:w-[134pxl.] bounce-in-top  "
              />
            </div>
            <div className="text">
              <h1 className="text-center font-bold text-[35px] leading-[70px] text-[#738482] mt-9">
           Become Reseller with e_ino
              </h1>
             
            </div>
            <div className="image relative top-[14px]">
              <img
                src={image}
                className="mx-ato m-auto h-[21rem] image-coin"
                alt=""
              />
            </div>

            <div className="flex flex-row items-center gap-4 justify-center mt-[7rem]">
              <p className="text-[14px]">License Policy</p>
              <p className="border-x p-[8px]  text-[14px] border-black">
                Privacy Policy
              </p>
              <p className="text-[14px]">Terms and Conition</p>
            </div>
            <p className="text-[#738482] text-[13px] text-center">
              Copyrights © 2023 All Rights Reserved by{" "}
              <span className="text-[#CB2229]">INO</span>{" "}
            </p>
          </div>

          {/* 01 end  */}
          {/* 02  */}
          <div className="item-form flex justify-center items-center">
            <section className="max-w-4xl p-6  lg:w-[93vh] sm:w-[93vh] mx-auto bg-white rounded-md shadow-md  mt-[3rem] fade-in-right">
              
            <p className="text-center  text-black font-all font-semibold ">
                Register as a reseller with ino  {" "}
              </p>


           


                <div className="flex flex-col justtify-center gap-6 mt-4 sm:grid-cols-2">
                  {/* <div className="md:max-w-[50%] lg:max-w-[50%]">
                    <label className="text-black" htmlFor="username">
                      User name *
                    </label>
                    <input
                      type="text"
                      className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                      name="userName"
                      value={formData.userName}
                      onChange={handleInputChange}
                    />
                  </div> */}
                  <div className="md:max-w-[50%] lg:max-w-[50%]">
                    <label className="text-black" htmlFor="username">
                    Email *
                    </label>
                    <input
                      type="text"
                      className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                      name="email"
                      value={email}
                      onChange={handleMobileNumberChange}
                     
                    />
                  </div>
            
              
                </div>
                <div className="flex justify-end mt-6">
                <button
onClick={handleSubmit}
  className="lg:mt-[4rem] xs:mt-[4rem] flex justify-center items-center mx-auto m-auto bg-[#CB2229] text-white rounded-full font-all p-[6px]  w-[18rem]   transform hover:scale-105 duration-500 ease-in-out "
 
>
  Register
</button>

                </div>
           
            </section>
          </div>
        </div>
    </>
  );
}
