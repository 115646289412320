import React, { useEffect, useState } from "react";

import {
  getAllProductCategories,
  findProduct,
} from "../../../../../api/userRegestration/CollectionForm";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { Link } from "react-router-dom";
import Spinner from "../../../../../component/Spinner";
import { TbSpeakerphone } from "react-icons/tb";
import PromotionFormBody from "../../../../PromotionForm/PromotionFormBody";

export default function Inputform() {
  const [categories, setCategories] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageBase64, setImageBase64] = useState(null); // Added state for the Base64 data
  const [loading, setLoading] = useState(false);
  const accessToken = localStorage.getItem("token");
  const [showModal, setShowModal] = useState(false);
  const [promotebutton, setPromoteButton] = useState(true);
const [getproductId, setgetproductId] = useState()
  const handleButtonClick = () => {
    toast.success("you are now at promotion form!", {
      position: "top-right",
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

    setPromoteButton(!promotebutton);
   
  };


  const [formData, setFormData] = useState({
    category: "",
    Productrate: "",
    moq: "",
    mqunit: "",
    ProductDetail: "",
    shareBusinessCard: false,
    followGuidelines: false,
  });

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
      convertImageToBase64(file); 
    } else {
      setSelectedImage(null);
      setImageBase64(null); 
    }
  };

  const convertImageToBase64 = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const base64Data = e.target.result;
      setImageBase64(base64Data);
    };
    reader.readAsDataURL(file);
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === "checkbox" ? checked : value;
    setFormData({ ...formData, [name]: newValue });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);

      const produtdata = {
        productName: formData.category,
        mediaUrl: imageBase64,
        details: formData.ProductDetail,
        amount: formData.Productrate,
        mediaType: "MEDIA",
        categorie: "find product",
        moq: formData.moq,
        mqu: formData.mqunit,
        type: "BUYER",
        // "type":"BUYER"
      };
      const Product = await findProduct(produtdata);
      console.log('PRODUCT ID FROM THE PARENT COMPONENT ' , Product.result.saveProduct._id  );
      let productId;
      setgetproductId(Product.result.saveProduct._id)
      console.log("id saved in the console storage ");
      if (Product && Product.result && Product.result.saveProduct) {
        productId = Product.result.saveProduct._id;
        console.log("Product ID:", productId);
      
        setgetproductId((productId) => {
         
          console.log("Previous Product ID:", productId);
          return productId;
        });

        console.log('\x1b[32m%s\x1b[0m', "product id from scattered state", productId);


      } else {
        console.log("Product ID not found in the response.");
      }

      console.log("post:", Product);
      console.log("here");
      // toaster
      toast.success("find product hitted  successfully", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      setShowModal(true);

      //
    } catch (error) {
      console.error("Error in :", error.message);
    } finally {
      setLoading(false); 
    }
  };

  useEffect(() => {
    getAllProductCategories(accessToken)
      .then((result) => {
        setCategories(result);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [accessToken]);

  useEffect(() => {
    if (getproductId) {
      console.log("Product ID from state:", getproductId);
    }
  }, [getproductId]);
  return (
    <>








  {promotebutton ? (
        <>
          <div className="conditional _ _ action _ _ a">
          <div className="conditional _ _ action _ _ a">

<div className="form">
  <form onSubmit={handleSubmit}>
    <div className="flex sm:flex-row  md:flex-row  flex-col lg:gap-[9rem] mt-[3rem] mb-[2rem]">
      {/* 1 */}
      <div className="form-input flex flex-col  w-[47%]">
        <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px] ml-[2.1rem]">
          <label
            htmlFor="select"
            className="text text-sm text-black w-[8rem] rounded-md bg-[white] relative top-2.5 ml-2 px-1 bg-w-fit-content text-center"
          >
            Product Categories
          </label>
          <select
            name="category"
            className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
            value={formData.category}
            onChange={handleInputChange}
          >
            <option value="" className="p-2" disabled>
              Select Category
            </option>
            {categories.map((category) => (
              <option
                key={category._id}
                value={category.name}
                className="bg-white  transition-colors duration-300 p-2"
              >
                {category.name}
              </option>
            ))}
          </select>
        </div>
        {/* 2  */}
        <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  ml-[2.1rem] ">
          <label
            htmlFor="input"
            className=" text text-sm  text-black w-[6rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
          >
            Product Rate
          </label>
          <input
            type="text"
            placeholder="Write here..."
            name="Productrate"
            className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
            value={formData.Productrate}
            onChange={handleInputChange}
          />
        </div>{" "}
        {/*  */}
        <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  ml-[2.1rem] ">
          <label
            htmlFor="input"
            className=" text text-sm  text-black w-[10rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
          >
            Minimum Order Quanity
          </label>
          <input
            type="text"
            placeholder="Write here..."
            name="moq"
            className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
            aria-autocomplete="list"
            value={formData.moq}
            onChange={handleInputChange}
          />
        </div>{" "}
        <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  ml-[2.1rem] ">
          <label
            htmlFor="input"
            className=" text text-sm  text-black w-[5rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
          >
            MQ UNIT
          </label>
          <input
            type="text"
            placeholder="Write here..."
            name="mqunit"
            className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
            value={formData.mqunit}
            onChange={handleInputChange}
          />
        </div>{" "}
        {/*  */}
        {/* 5  */}
        {/* text area  */}
        <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px] ml-[2.1rem]">
          <label
            htmlFor="input"
            className="text text-sm text-black w-[7rem] rounded-md bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
          >
            Product Details 
          </label>
          <textarea
            placeholder="Write here..."
            name="ProductDetail"
            rows="7"
            className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
            value={formData.ProductDetail}
            onChange={handleInputChange}
          ></textarea>
        </div>
      </div>

      {/* 2 */}

      <div className="inline-flex flex-col mt-[4rem] lg:gap-[3rem]">
        <div className="flex items-center justify-center w-full">
          <label
            style={{
              display: selectedImage ? "none" : "block",
            }}
            htmlFor="dropzone-file"
            className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 shark:hover:bg-bray-800 shark:bg-gray-700 hover:bg-gray-100 shark:border-gray-600 shark:hover:border-gray-500 shark:hover:bg-gray-600"
          >
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <svg
                className="w-8 h-8 mb-4 text-gray-500 shark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 16"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                />
              </svg>
              <p className="mb-2 text-sm text-gray-500 shark:text-gray-400">
                <span className="font-semibold">Click to upload</span> or
                drag and drop
              </p>
              <p className="text-xs text-gray-500 shark:text-gray-400">
                SVG, PNG, JPG, or GIF (MAX. 800x400px)
              </p>
            </div>
            <input
              id="dropzone-file"
              type="file"
              className="hidden"
              onChange={handleImageChange}
            />
          </label>
          {selectedImage && (
            <div className="flex items-center  w-auto p-[10px] h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 shark:hover:bg-bray-800 shark:bg-gray-700 hover:bg-gray-100 shark:border-gray-600 shark:hover:border-gray-500 shark:hover:bg-gray-600">
              <img
                className="w-full h-44 mx-auto "
                src={selectedImage}
                alt="Selected"
              />
            </div>
          )}
        </div>

        <div className="/">
          <div class="flex items-center ">
            <input
              id="link-checkbox"
              type="checkbox"
              value=""
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 shark:focus:ring-blue-600 shark:ring-offset-gray-800 focus:ring-2 shark:bg-gray-700 shark:border-gray-600"
            />
            <label
              for="link-checkbox"
              class="ml-2 text-sm font-all font-semibold text-gray-900 shark:text-gray-300"
            >
              I agree to share my Bussiness card with Supplier .
            </label>
          </div>

          <div class="flex items-center mt-3">
            <input
              id="link-checkbox"
              type="checkbox"
              value=""
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 shark:focus:ring-blue-600 shark:ring-offset-gray-800 focus:ring-2 shark:bg-gray-700 shark:border-gray-600"
            />
            <label
              for="link-checkbox"
              class="ml-2 text-sm font-all font-semibold text-gray-900 shark:text-gray-300"
            >
              I agree to follow buyer posting guideline .
            </label>
          </div>
        </div>

        {/* buttin action */}

        <div class="button-action flex flex-col items-center mt-6">
          <button
            type="submit"
            class=" bg-[#CB2229] flex flex-row justify-center items-center gap-2 text-white rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative"
            disabled={loading}
          >
            {loading && <Spinner />} Share Inputs
          </button>
          {/* <Link to="/create_module_promotion" > */}
          <button class="mb-6 border-[1px] border-[#CB2229] text-black rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative  ">
            Create a Campaign
          </button>
          {/* </Link> */}
        </div>
      </div>
      {/*  */}
    </div>
  </form>
</div>

{showModal ? (
  <>
    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none fade-in-right">
      <div className="">
        {/*content*/}
        <div className="border-2 border-gray-300 drop-shadow-lg rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          {/*header*/}
          <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
            <h3 className="text-3xl font-semibold capitalize font-all ">
              input action
            </h3>
            <button
              className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              onClick={() => setShowModal(false)}
            >
              <span className="bg-transparent text-red-400  h-6 w-6 text-2xl block outline-none focus:outline-none">
                ×
              </span>
            </button>
          </div>
          {/*body*/}
          <div className="relative p-6 flex-auto">
            <div className="my-4">
              <div class="button-action flex flex-col items-center mt-6">
                <button  onClick={handleButtonClick} class=" bg-[#CB2229] flex flex-row justify-center items-center gap-2 text-white rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative flex flex-row items-center gap-3">
                <TbSpeakerphone />   Promote Inputs
                </button>
                {/* <Link to="/create_module_promotion" > */}
                <button
                  onClick={() => setShowModal(false)}
                  class="mb-6 border-[1px] border-[#CB2229] text-black rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative  "
                >
                  continue withour promotion
                </button>
                {/* </Link> */}
              </div>
            </div>
          </div>
          {/*footer*/}
          <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b"></div>
        </div>
      </div>
    </div>
    {/* <div className="opacity-25 fixed inset-0 z-40 bg-black"></div> */}
  </>
) : null}

<ToastContainer
  position="top-right"
  autoClose={5000}
  hideProgressBar={false}
  newestOnTop={false}
  closeOnClick
  rtl={false}
  pauseOnFocusLoss
  draggable
  pauseOnHover
  theme="light"
/>
</div>
          </div>
        </>
      ) : (
        <>

          <div className="conditional action b">
          <PromotionFormBody data={getproductId} />

          </div>
        </>
      )}

      {/*  */}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}
