import React, { useEffect, useState } from "react";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { Rating } from "flowbite-react";
import {getAllSellerProducts} from "../../../../../api/userRegestration/CollectionForm"

import { useNavigate } from "react-router-dom";
// 
export default function ProductForYou() {
  // 
  const accessToken = localStorage.getItem("token");
  const [Product, setProduct] = useState([]);
  const scrollLeft = () => {
    document.getElementById("contentB").scrollLeft -= 400;
  };
  const scrollRight = () => {
    document.getElementById("contentB").scrollLeft += 400;
  };
  const [showIcon1, setShowIcon1] = useState(true);
  const toggleIcon = () => {
    setShowIcon1(!showIcon1);
  };

// 
  useEffect(() => {
    getAllSellerProducts(accessToken)
      .then((result) => {
        setProduct(result);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [accessToken]);
const navigate = useNavigate();
// 
const handleroute = (productId) => {
  navigate(`/findproducts/products_for_you_/product_detail_/${productId}`);
};
  return (
    <>
      <div className="relative">
        <h1 className="text-center font-all font-extrabold text-[#263238] lg:text-[19px]">
          Products For You
        </h1>

        <div className="absolute right-0 lg:top-[3.2rem] z-10 xs:top-[20px]">
          <button
            onClick={scrollLeft}
            className="relative lg:top-[77px] bg-[#0000002e] lg:h-[3rem] p-2 m-2 rounded-md lg:right-[67.5rem] carousel-bg"
          >
            <FiChevronLeft className="relative lg:right-[1px] text-black" />
          </button>
          <button
            onClick={scrollRight}
            className=" relative lg:top-[77px] bg-[#0000002e] lg:h-[3rem] p-2 m-2 rounded-md lg:left-[65px]"
          >
            <FiChevronRight className="relative text-black" />
          </button>
        </div>
        <div
          id="contentB"
          className="carousel p-4 flex items-center justify-start overflow-x-auto scroll-smooth scrollbar-hide lg:h-[auto] -m-4 mt-1"
        >
          {Product.map((item) => (
            <div      onClick={() => handleroute(item._id)}   className="cursor-pointer carousel-card lg:ml-[-18px]"    key={item._id} >
              <div className="p-4 lg:w-[246px] xs:w-[14rem] drop-shadow-xl">
                <div className="bg-white p-[5px] absolute z-10 rounded-full right-[31px] top-[22px] cursor-pointer">
                  {showIcon1 ? (
                    <AiOutlineHeart onClick={toggleIcon} />
                  ) : (
                    <AiFillHeart
                      className="text-red-400"
                      onClick={toggleIcon}
                    />
                  )}
                </div>

                <div className="lg:h-[260px] lg:w-[202px] shadow-inner pb-24 rounded-lg overflow-hidden text-center relative intrest-card bg-[#ffff] h-[137px]">
                  <img
                    src={item.mediaUrl}
                  
                    className="lg:h-[142px] l:w-[242px]"
                    alt=""
                  />

                  <div className="flex flex-row items-center justify-center gap-[15px]">
                    <h5 className="text-black font-all font-bold lg:mt-[9px]">
                      {item.productName}
                    </h5>

                    <Rating className="relative top-[3px]">
                      <Rating.Star className="text-yellow-300 text-[10px]" />
                      <Rating.Star className="text-yellow-300 text-[10px]" />
                      <Rating.Star />
                      <p className="ml-2 font-medium text-gray-500 dark:text-gray-400 text-[10px]">
                        4.95 /5
                      </p>
                    </Rating>
                  </div>

                  <p className="font-extra-light text-gray-500 italic text-[11px] mt-[7px]">
                    {item.details}
                  </p>

                  <div className="flex flex-row items-center justify-center gap-[3rem]">
                    <div className="flex flex-row item-1 gap-[7px]">
                      <p className="text-gray-500 italic text-[11px]">
                        MOQ: {item.moq}
                      </p>
                      <p className="text-gray-500 italic text-[11px]">
                        MQU: {item.mqu}
                      </p>
                    </div>

                    <div className="item-b item-end">
                      <span style={{ color: "red" }}>OMR {item.amount}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
