import axios from "axios";
import * as ENDPOINTS from "../URlcollection";

// register user phonenumber  && send otp to numbr
export const registerUser = async (mobileNumber) => {
  try {
    const url = "https://inobackend-production.up.railway.app/api/v1/register";
    const response = await axios.post(
      url,
      { mobileNumber },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    console.log(response);
    if (response.status === 200) {
      const responseData = response.data;

      console.log(
        "%cSuccess: status code 200",
        "color: green; font-weight: bold;"
      );
      console.log("Response Message:", responseData.responseMessage);
      return responseData.responseMessage;
    } else {
      if (response.data && response.data.error) {
        throw new Error(response.data.error);
      } else {
        throw new Error("Registration failed. Please try again.");
      }
    }
  } catch (error) {
    console.error("An error occurred during registration:", error.message);
    throw new Error("An error occurred during registration.");
  }
};

// get verify for verifying mobile number 
export const Receive_otp = async (mobileNumber) => {

  try {
    const response = await axios.post(
      "https://inobackend-production.up.railway.app/api/v1/user/register",
      {
        mobileNumber: `${mobileNumber}`,
      }
      ,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    if (response.status === 200) {
      const registeredUser = response.data;
      console.log("Registered User:", registeredUser);
      return registeredUser;
    } else {
      throw new Error("Failed to register user");
    }
  } catch (error) {
    console.error("Error registering user:", error.message);
    throw new Error("Failed to register user");
  }


}




// veification of otp  &&
export async function verifyOTP(mobileNumber, otp) {
  console.log("in");
  try {
    const response = await axios.post(
      "https://inobackend-production.up.railway.app/api/v1/user/verifyOTP",
      {
        mobileNumber: `${mobileNumber}`,
        otp: otp,
      }
    );

    console.log(response);
    // const response = await axios.post(
    //   "https://inobackend-production.up.railway.app/api/v1/user/verifyOTP",
    //   {
    //     mobileNumber: `+${mobileNumber}`,
    //     otp: otp,
    //   },{}
    // );

    const token = response.data.token;
    localStorage.setItem("token", token);

    return response.data;
  } catch (error) {
    // Log the actual error message
    console.error("Error while verifying OTP:", error.message);
    throw error; // Rethrow the error for the caller to handle
  }
}




// register user information form *form rendering after verification of otp
export const registerUserdata = async (userData) => {
  try {
    const response = await axios.post(
      ENDPOINTS.USER_API.Register_Profile,
      userData,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    if (response.status === 200) {
      const registeredUser = response.data;
      console.log("Registered User:", registeredUser);
      return registeredUser;
    } else {
      throw new Error("Failed to register user");
    }
  } catch (error) {
    console.error("Error registering user:", error.message);
    throw new Error("Failed to register user");
  }
};
//creatig post api
export const createPost = async (postData) => {
  try {
    const response = await axios.post(
      ENDPOINTS.USER_API.Create_Post,
      postData,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    if (response.status === 200) {
      const postdata = response.data;
      console.log(" resposnse post:", postdata);
      return postdata;
    } else {
      throw new Error("Failed to register user");
    }
  } catch (error) {
    console.error("Error  :", error.message);
    throw new Error("Failed to post ");
  }
};
// async func for post project
export const postProject = async (ProjectData) => {
  try {
    const response = await axios.post(
      ENDPOINTS.USER_API.Post_Project,
      ProjectData,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    if (response.status === 200) {
      const projectdata = response.data;
      console.log(" resposnse post:", projectdata);
      return projectdata;
    } else {
      throw new Error("Failed");
    }
  } catch (error) {
    console.error("Error  :", error.message);
    throw new Error("Failed to projectdata ");
  }
};
// async fun for  populating products cateogries in dopdown for all modules
export const categoryData = async () => {
  try {
    const response = await axios.get(ENDPOINTS.USER_API.Cateogry_Data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });

    if (response.status === 200) {
      return response.data.data;
    } else {
      throw new Error(`Failed to fetch category. Status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error fetching category:", error);
    throw new Error("Failed to fetch category");
  }
};
//
export const getAllProductCategories = async (accessToken) => {
  try {
    const response = await axios.get(
      `https://inobackend-production.up.railway.app/api/v1/admin/getAllProductCategories`,
      {
        headers: {
          Token: accessToken,
        },
      }
    );

    if (response.status === 200) {
      return response.data.result;
    } else {
      throw new Error("Failed to fetch product categories");
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
// 
export const interst_categories = async (accessToken) => {
  try {
    const response = await axios.get(
      `https://inobackend-production.up.railway.app/api/v1/admin/getAllIdentifications`,
      {
        headers: {
          Token: accessToken,
        },
      }
    );

    if (response.status === 200) {
      return response.data.result;
    } else {
      throw new Error("Failed to fetch product categories");
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};




// 



//  async fun for add product // module row_#_4
export const addProduct = async (produtdata) => {
  try {
    const response = await axios.post(
      ENDPOINTS.USER_API.Create_Product,
      produtdata,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    if (response.status === 200) {
      const productdata = response.data;
      console.log(" resposnse post:", productdata);
      return productdata;
    } else {
      throw new Error("Failed to  user");
    }
  } catch (error) {
    console.error("Error  :", error.message);
    throw new Error("Failed to productdata ");
  }
};
//  async func for find product input // module row_#_1
export const findProduct = async (produtdata) => {
  try {
    const response = await axios.post(
      ENDPOINTS.USER_API.Create_Product,
      produtdata,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    if (response.status === 200) {
      const productdata = response.data;
      console.log(" resposnse post:", productdata);
      return productdata;
    } else {
      throw new Error("Failed to  user");
    }
  } catch (error) {
    console.error("Error  :", error.message);
    throw new Error("Failed to productdata ");
  }
};
//  async func or listing  service  // module_row_#_5
export const listService = async (service_List_Data) => {
  try {
    const response = await axios.post(
      ENDPOINTS.USER_API.Create_Service,
      service_List_Data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    if (response.status === 200) {
      const productdata = response.data;
      console.log(" resposnse List Service :", productdata);
      return productdata;
    } else {
      throw new Error("Failed to  List Service");
    }
  } catch (error) {
    console.error("Error  :", error.message);
    throw new Error("Failed to LisService ");
  }
};
//  async dunc for explore service // module_row_#_2
export const ExploreService = async (service_List_Data) => {
  try {
    const response = await axios.post(
      ENDPOINTS.USER_API.Create_Service,
      service_List_Data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    if (response.status === 200) {
      const productdata = response.data;
      console.log(" resposnse List Service :", productdata);
      return productdata;
    } else {
      throw new Error("Failed to  List Service");
    }
  } catch (error) {
    console.error("Error  :", error.message);
    throw new Error("Failed to LisService ");
  }
};
//
export const CreateJOb = async (Create_JOB_List_Data) => {
  try {
    const response = await axios.post(
      ENDPOINTS.USER_API.Create_Job,
      Create_JOB_List_Data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    if (response.status === 200) {
      const productdata = response.data;
      console.log(" resposnse List Service :", productdata);
      return productdata;
    } else {
      throw new Error("Failed to  List Service");
    }
  } catch (error) {
    console.error("Error  :", error.message);
    throw new Error("Failed to LisService ");
  }
};
//
export const ProductListDATA = async () => {
  try {
    const response = await axios.get(ENDPOINTS.USER_API.All_Product_list, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });

    if (response.status === 200) {
      return response.data.data;
    } else {
      throw new Error(`Failed to fetch data. Status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error fetching category:", error);
    throw new Error("Failed to fetch category");
  }
};
export const getServiceListDa = async (accessToken) => {
  try {
    const response = await axios.get(
      `https://inobackend-production.up.railway.app/api/v1/admin/getAllServiceCategories`,
      {
        headers: {
          token: accessToken,
        },
      }
    );

    if (response.status === 200) {
      return response.data.result;
    } else {
      throw new Error("Failed to fetch service  categories");
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
// 
export const job_categories = async (accessToken) => {
  try {
    const response = await axios.get(
      `https://inobackend-production.up.railway.app/api/v1/admin/getAllServiceCategories`,
      {
        headers: {
          token: accessToken,
        },
      }
    );

    if (response.status === 200) {
      return response.data.result;
    } else {
      throw new Error("Failed to fetch service  categories");
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
// 
export const user_inerest = async (accessToken) => {
  try {
    const response = await axios.get(
      `https://inobackend-production.up.railway.app/api/v1/admin/getAllInterests`,
      {
        headers: {
          token: accessToken,
        },
      }
    );

    if (response.status === 200) {
      return response.data.result;
    } else {
      throw new Error("Failed to fetch service  categories");
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};


//
export const ApprovedProducts = async (accessToken) => {
  try {
    const response = await axios.get(
      `https://inobackend-production.up.railway.app/api/v1/user/getAllProductList`,
      {
        headers: {
          token: accessToken,
        },
      }
    );

    if (response.status === 200) {
      return response.data.result;
    } else {
      throw new Error("Failed to fetch products");
    }
  } catch (error) {
    console.error("Error fetching products:", error);
    throw error;
  }
};
// fetch all stores list
export const getAllStores = async (accessToken) => {
  try {
    const response = await axios.get(
      `https://inobackend-production.up.railway.app/api/v1/store/listUserStores`,
      {
        headers: {
          Token: accessToken,
        },
      }
    );

    if (response.status === 200) {
      return response.data.result;
    } else {
      throw new Error("Failed to fetch store categories");
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
// register store
export const registerSrore = async (StoreData) => {
  try {
    const response = await axios.post(
      ENDPOINTS.Store_Urls.Register_Store,
      StoreData,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    if (response.status === 200) {
      const registeredUser = response.data;
      console.log("Registered store:", registeredUser);
      return registeredUser;
    } else {
      throw new Error("Failed to register user");
    }
  } catch (error) {
    console.error("Error registering user:", error.message);
    throw new Error("Failed to register store");
  }
};
// stre view details
export const fetchStoreDetails = async (storeId) => {
  try {
    const apiUrl = `https://inobackend-production.up.railway.app/api/v1/store/storeView?storeId=${storeId}`;

    const response = await axios.get(apiUrl, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(
        `Failed to fetch store details. Status: ${response.status}`
      );
    }
  } catch (error) {
    console.error("Error fetching store details:", error);
    throw new Error("Failed to fetch store details");
  }
};


export const fetchPostDetails = async (postId) => {
  try {
    const apiUrl = `https://inobackend-production.up.railway.app/api/v1/user/postView?postId=${postId}`;

    const response = await axios.get(apiUrl, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(
        `Failed to fetch store details. Status: ${response.status}`
      );
    }
  } catch (error) {
    console.error("Error fetching store details:", error);
    throw new Error("Failed to fetch store details");
  }
};


//find job form
export const Findjob = async (job_data) => {
  try {
    const response = await axios.post(ENDPOINTS.USER_API.Create_Job, job_data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });

    if (response.status === 200) {
      const productdata = response.data;
      console.log(" resposnse List Service :", productdata);
      return productdata;
    } else {
      throw new Error("Failed to  List Service");
    }
  } catch (error) {
    console.error("Error  :", error.message);
    throw new Error("Failed to LisService ");
  }
};

// show products in find product module
export const getAllSellerProducts = async (accessToken) => {
  try {
    const response = await axios.get(
      ENDPOINTS.USER_API.Findproduct_SellerProductList,
      {
        headers: {
          Token: accessToken,
        },
      }
    );

    if (response.status === 200) {
      return response.data.result;
    } else {
      throw new Error("Failed to fetch store categories");
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
// get products detail view _ information from user web
export const fetchSProductDetails = async (productId) => {
  try {
    const apiUrl = `https://inobackend-production.up.railway.app/api/v1/user/productView?productId=${productId}`;

    const response = await axios.get(apiUrl, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(
        `Failed to fetch store details. Status: ${response.status}`
      );
    }
  } catch (error) {
    console.error("Error fetching store details:", error);
    throw new Error("Failed to fetch store details");
  }
};
// get all buyer products
export const getAllBuyerProducts = async (accessToken) => {
  try {
    const response = await axios.get(
      ENDPOINTS.USER_API.Addproduct_buyerProductlist,
      {
        headers: {
          Token: accessToken,
        },
      }
    );

    if (response.status === 200) {
      return response.data.result;
    } else {
      throw new Error("Failed to fetch  ");
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
//
export const fetchBuyerProductDetails = async (productId) => {
  try {
    const apiUrl = `https://inobackend-production.up.railway.app/api/v1/user/productView?productId=${productId}`;

    const response = await axios.get(apiUrl, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(
        `Failed to fetch store details. Status: ${response.status}`
      );
    }
  } catch (error) {
    console.error("Error fetching store details:", error);
    throw new Error("Failed to fetch store details");
  }
};

// 
export const fetchBussinessCardDetails = async (businessCardId) => {
  try {
    const apiUrl = `https://inobackend-production.up.railway.app/api/v1/user/viewBusinessCard?businessCardId=${businessCardId}`;

    const response = await axios.get(apiUrl, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(
        `Failed to fetch store details. Status: ${response.status}`
      );
    }
  } catch (error) {
    console.error("Error fetching store details:", error);
    throw new Error("Failed to fetch store details");
  }
};



export const EditBussinessCards = async (updatedBussinessinfo) => {
  try {
    const apiUrl = `https://inobackend-production.up.railway.app/api/v1/user/editBusinessCard`;

    const response = await axios.post(ENDPOINTS.USER_API.edit_Bussiness_Card, updatedBussinessinfo, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(
        `Failed to fetch store details. Status: ${response.status}`
      );
    }
  } catch (error) {
    console.error("Error fetching store details:", error);
    throw new Error("Failed to fetch store details");
  }
};
export const edit_user_store = async (updatedBussinessinfo) => {
  try {

    const response = await axios.post(ENDPOINTS.Store_Urls.update_user_store, updatedBussinessinfo, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(
        `Failed to fetch store details. Status: ${response.status}`
      );
    }
  } catch (error) {
    console.error("Error fetching store details:", error);
    throw new Error("Failed to fetch store details");
  }
};

// 
export const getAllSellerServiceList = async (accessToken) => {
  try {
    const response = await axios.get(
      ENDPOINTS.USER_API.Explore_service_SellerserviceList,
      {
        headers: {
          Token: accessToken,
        },
      }
    );

    if (response.status === 200) {
      return response.data.result;
    } else {
      throw new Error("Failed to fetch store categories");
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
//
export const getAllBuyerServiceList = async (accessToken) => {
  try {
    const response = await axios.get(
      ENDPOINTS.USER_API.List_service_buyerServiceList,
      {
        headers: {
          Token: accessToken,
        },
      }
    );

    if (response.status === 200) {
      return response.data.result;
    } else {
      throw new Error("Failed to fetch store categories");
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
//
export const getAllSellerjobList = async (accessToken) => {
  try {
    const response = await axios.get(
      ENDPOINTS.USER_API.Findjob_seller_joblist,
      {
        headers: {
          Token: accessToken,
        },
      }
    );

    if (response.status === 200) {
      return response.data.result;
    } else {
      throw new Error("Failed to fetch store categories");
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
//
export const getAllBuyerjobList = async (accessToken) => {
  try {
    const response = await axios.get(ENDPOINTS.USER_API.PostJob_buyer_joblist, {
      headers: {
        Token: accessToken,
      },
    });

    if (response.status === 200) {
      return response.data.result;
    } else {
      throw new Error("Failed to fetch store categories");
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
//
export const fetchServiceDetails = async (productId) => {
  try {
    const apiUrl = `https://inobackend-production.up.railway.app/api/v1/user/serviceView?serviceId=${productId}`;

    const response = await axios.get(apiUrl, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(
        `Failed to fetch store details. Status: ${response.status}`
      );
    }
  } catch (error) {
    console.error("Error fetching store details:", error);
    throw new Error("Failed to fetch store details");
  }
};
//
export const fetchServiceListDetails = async (productId) => {
  try {
    const apiUrl = `https://inobackend-production.up.railway.app/api/v1/user/serviceView?serviceId=${productId}`;

    const response = await axios.get(apiUrl, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(
        `Failed to fetch store details. Status: ${response.status}`
      );
    }
  } catch (error) {
    console.error("Error fetching store details:", error);
    throw new Error("Failed to fetch store details");
  }
};
//
export const fetchJobDetails = async (jobId) => {
  try {
    const apiUrl = `https://inobackend-production.up.railway.app/api/v1/user/jobView?jobId=${jobId}`;

    const response = await axios.get(apiUrl, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(
        `Failed to fetch store details. Status: ${response.status}`
      );
    }
  } catch (error) {
    console.error("Error fetching store details:", error);
    throw new Error("Failed to fetch store details");
  }
};
// gov market Project
export const GovProjectList = async (accessToken) => {
  try {
    const response = await axios.get(ENDPOINTS.Gov_Urls.gov_project_List, {
      headers: {
        Token: accessToken,
      },
    });

    if (response.status === 200) {
      return response.data.result;
    } else {
      throw new Error("Failed to fetch store categories");
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
// gov project details
export const GovProjectDetails = async (jobId) => {
  try {
    const apiUrl = `https://inobackend-production.up.railway.app/api/v1/user/projectView?projectId=${jobId}`;

    const response = await axios.get(apiUrl, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(
        `Failed to fetch store details. Status: ${response.status}`
      );
    }
  } catch (error) {
    console.error("Error fetching store details:", error);
    throw new Error("Failed to fetch store details");
  }
};
//
export const CreateBussinessCards = async (produtdata) => {
  try {
    const response = await axios.post(
      ENDPOINTS.USER_API.create_Bussiness_card,
      produtdata,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    if (response.status === 200) {
      const projectdata = response.data;
      console.log(" resposnse post:", projectdata);
      return projectdata;
    } else {
      throw new Error("Failed");
    }
  } catch (error) {
    console.error("Error  :", error.message);
    throw new Error("Failed to projectdata ");
  }
};
// 
export const delete_bussiness_card = async (produtdata) => {
  try {
    const response = await axios.post(
      ENDPOINTS.USER_API.delete_bussiness_Card,
      produtdata,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    if (response.status === 200) {
      const projectdata = response.data;
      console.log(" resposnse post:", projectdata);
      return projectdata;
    } else {
      throw new Error("Failed");
    }
  } catch (error) {
    console.error("Error  :", error.message);

  }
};

export const delete_user_store = async (produtdata) => {
  try {
    const response = await axios.post(
      ENDPOINTS.Store_Urls.delete_store,
      produtdata,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    if (response.status === 200) {
      const projectdata = response.data;
      console.log(" resposnse post:", projectdata);
      return projectdata;
    } else {
      throw new Error("Failed");
    }
  } catch (error) {
    console.error("Error  :", error.message);
    throw new Error("Failed to projectdata ");
  }
};
// 


// 
export const fetch_referal_code = async () => {
  try {
    const response = await axios.get(ENDPOINTS.USER_API.get_Referal_code, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(
        `Failed to fetch store details. Status: ${response.status}`
      );
    }
  } catch (error) {
    console.error("Error fetching store details:", error);
    throw new Error("Failed to fetch store details");
  }
};
//

export const fetch_posts = async () => {
  try {
    const response = await axios.get(ENDPOINTS.USER_API.fetch_all_post_list, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error("Error fetching wallet details:", error);
    // throw new Error("Failed to fetch store details");
  }
};

export const fetch_wallet_info = async () => {
  try {
    const response = await axios.get(ENDPOINTS.wallet_url.wallet_info, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error("Error fetching wallet details:", error);
    // throw new Error("Failed to fetch store details");
  }
};
//  for depositig amount in the wallet 
export const deposit_money = async (produtdata) => {
  try {
    const response = await axios.post(
      ENDPOINTS.wallet_url.depoist_funs,
      produtdata,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    if (response.status === 200) {
      const productdata = response.data;
      console.log(" resposnse post:", productdata);
      return productdata;
    } else {
      throw new Error("Failed to  user");
    }
  } catch (error) {
    console.error("Error  :", error.message);
    // throw new Error("Failed to productdata ");
  }
};
// 
export const withdraw_money = async (withdrwal_value) => {
  try {
    const response = await axios.post(
      ENDPOINTS.wallet_url.withdraw_funds,
      withdrwal_value,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    if (response.status === 200) {
      const productdata = response.data;
      console.log(" resposnse post:", productdata);
      return productdata;
    } else {
      throw new Error("Failed to  user");
    }
  } catch (error) {
    console.error("Error  :", error.message);
    // throw new Error("Failed to productdata ");
  }
};

// 
// 
export const fetch_Profile_Status = async () => {
  try {
    const response = await axios.get(ENDPOINTS.USER_API.get_Referal_code, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(
        `Failed to fetch store details. Status: ${response.status}`
      );
    }
  } catch (error) {
    console.error("Error fetching store details:", error);
    throw new Error("Failed to fetch store details");
  }
};

// 
export const SendReferalCode = async (Referal_data) => {
  try {
    const response = await axios.post(
      ENDPOINTS.USER_API.add_Referal_code,
      Referal_data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    if (response.status === 200) {
      const productdata = response.data;
      console.log(" resposnse List Service :", productdata);
      return productdata;
    } else {
      throw new Error("Failed to  List Service");
    }
  } catch (error) {
    console.error("Error  :", error.message);
    throw new Error("Failed to LisService ");
  }
};

// become reseller
export const BecomeReseller = async (resellerUserData) => {
  try {
    const response = await axios.post(
      ENDPOINTS.Reseller_Urls.Become_Reseller,
      resellerUserData,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    if (response.status === 200) {
      const productdata = response.data;
      console.log(" resposnse List Service :", productdata);
      return productdata;
    } else {
      throw new Error("Failed to  List Service");
    }
  } catch (error) {
    console.error("Error  :", error.message);
    throw new Error("Failed to LisService ");
  }
};
//

// 

export async function Reseller_otp(email, concatenated, token) {
  console.log("in");
  try {
    const response = await axios.post(
      "https://inobackend-production.up.railway.app/api/v1/user/verifyMailAndUpdate",
      {
        email: email,
        otp: concatenated,
      }, {
      headers: {
        token: token,
      },
    }
    );

    console.log(response);



    return response.data;
  } catch (error) {
    // Log the actual error message
    console.error("Error while verifying OTP:", error.message);
    throw error; // Rethrow the error for the caller to handle
  }
}
export const BecomeaReseller = async () => {

  try {


    const response = await axios.get(ENDPOINTS.Reseller_Urls.BecomeReseller, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(
        `Failed to fetch store details. Status: ${response.status}`
      );
    }
  } catch (error) {
    console.error("Error fetching store details:", error);
    throw new Error("Failed to fetch store details");
  }
};
// other projects
export const ListOtherProjects = async (accessToken) => {
  try {
    const response = await axios.get(ENDPOINTS.Gov_Urls.Other_Projects, {
      headers: {
        Token: accessToken,
      },
    });

    if (response.status === 200) {
      return response.data.result;
    } else {
      throw new Error("Failed to fetch store categories");
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
// 
export const ListAllProducts = async (accessToken) => {
  try {
    const response = await axios.get(
      `https://inobackend-production.up.railway.app/api/v1/user/getAllProductList`,
      {
        headers: {
          Token: accessToken,
        },
      }
    );

    if (response.status === 200) {
      return response.data.result;
    } else {
      throw new Error("Failed to fetch store categories");
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
export const ListAllJobs = async (accessToken) => {
  try {
    const response = await axios.get(
      `https://inobackend-production.up.railway.app/api/v1/user/getAllJobList`,
      {
        headers: {
          Token: accessToken,
        },
      }
    );

    if (response.status === 200) {
      return response.data.result;
    } else {
      throw new Error("Failed to fetch store categories");
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const ListAllPost = async (accessToken) => {
  try {
    const response = await axios.get(
      `https://inobackend-production.up.railway.app/api/v1/user/getAllPostList`,
      {
        headers: {
          Token: accessToken,
        },
      }
    );

    if (response.status === 200) {
      return response.data.result;
    } else {
      throw new Error("Failed to fetch store categories");
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const ListAllService = async (accessToken) => {
  try {
    const response = await axios.get(
      `https://inobackend-production.up.railway.app/api/v1/user/getAllServiceList`,
      {
        headers: {
          Token: accessToken,
        },
      }
    );

    if (response.status === 200) {
      return response.data.result;
    } else {
      throw new Error("Failed to fetch store categories");
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const ListAllProjects = async (accessToken) => {
  try {
    const response = await axios.get(
      `https://inobackend-production.up.railway.app/api/v1/user/getAllProjectList`,
      {
        headers: {
          Token: accessToken,
        },
      }
    );

    if (response.status === 200) {
      return response.data.result;
    } else {
      throw new Error("Failed to fetch store categories");
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

// editable inputs
export const delete_product_input = async (item_id) => {

  const url = "https://inobackend-production.up.railway.app/api/v1/user/deleteUserProduct"


  try {


    const response = await axios.post(url, item_id, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(
        `Failed to fetch store details. Status: ${response.status}`
      );
    }
  } catch (error) {
    console.error("Error fetching store details:", error);
    throw new Error("Failed to fetch store details");
  }
};
// 
export const delete_service_input = async (item_id) => {

  const url = "https://inobackend-production.up.railway.app/api/v1/user/deleteUserProduct"


  try {


    const response = await axios.post(url, item_id, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(
        `Failed to fetch store details. Status: ${response.status}`
      );
    }
  } catch (error) {
    console.error("Error fetching store details:", error);
    throw new Error("Failed to fetch store details");
  }
};
//
export const delete_job_input = async (item_id) => {

  const url = "https://inobackend-production.up.railway.app/api/v1/user/deleteUserjob"


  try {


    const response = await axios.post(url, item_id, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(
        `Failed to fetch store details. Status: ${response.status}`
      );
    }
  } catch (error) {
    console.error("Error fetching store details:", error);
    throw new Error("Failed to fetch store details");
  }
};
// 
export const delete_post_input = async (item_id) => {

  const url = "https://inobackend-production.up.railway.app/api/v1/user/deleteUserPost"


  try {


    const response = await axios.post(url, item_id, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(
        `Failed to fetch store details. Status: ${response.status}`
      );
    }
  } catch (error) {
    console.error("Error fetching store details:", error);
    throw new Error("Failed to fetch store details");
  }
};
// 
export const delete_project_input = async (item_id) => {

  const url = "https://inobackend-production.up.railway.app/api/v1/user/deleteUserProject"


  try {


    const response = await axios.post(url, item_id, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(
        `Failed to fetch store details. Status: ${response.status}`
      );
    }
  } catch (error) {
    console.error("Error fetching store details:", error);
    throw new Error("Failed to fetch store details");
  }
};