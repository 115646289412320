import React, { useRef } from "react";

import home from "../staticimages/home.png";
import Explore from "../staticimages/explore.png";
import Product from "../staticimages/addproduct.png";
import ervice from "../staticimages/listservice.png";
import Post from "../staticimages/jobpost.png";
import Member from "../staticimages/member.png";
import laptop from "../staticimages/laptop.png";
import { Link, NavLink } from "react-router-dom";
import { Card } from "flowbite-react";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import img from "../../../assets/img-card/images-card.png";
import img2 from "../../../assets/img-card/2.png";
function Services() {
  const cards = [
    {
      title: "Find Product",
      description:
        "Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.",
      link: "#",
      color: "bg-yellow-400",

      img: img,
    },
    {
      title: "Explore Service",
      description:
        "Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.",
      link: "#",
      color: "bg-yellow-400",
      height: "131px",
      img: img2,
    },
    {
      title: "Find Jobs",
      description:
        "Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.",
      link: "#",
      color: "bg-yellow-400",
    },

    {
      title: "Add Product",
      description:
        "Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.",
      link: "#",
      color: "bg-[#f7dedfc9]",
    },

    {
      title: "List Your Service",
      description:
        "Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.",
      link: "#",
      color: "bg-yellow-400",
    },
    {
      title: "Post Job",
      description:
        "Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.",
      link: "#",
      color: "bg-[#f7dedfc9]",
    },
    {
      title: "Post Project",
      description:
        "Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.",
      link: "#",
      color: "bg-yellow-400",

      img: img,
    },
  ];
  const contentRef = useRef(null);

  const scrollLeft = () => {
    if (contentRef.current) {
      contentRef.current.scrollLeft -= 400;
    }
  };

  const scrollRight = () => {
    if (contentRef.current) {
      contentRef.current.scrollLeft += 400;
    }
  };

  return (
    <>
      <div className="item-desktop xs:hidden ">
        <div className="flex flex-col gap-[17px] xs:mt-[18rem]">
          <div className="flex flex-row gap-4">
            <NavLink to="/findproducts">
              {" "}
              <div className="bg-white shadow-l text-center lg:h-[183px] lg:w-[197px] rounded-md  cursor-pointer  transform hover:scale-105 duration-500 ease-in-out">
                <div className="img">
                  <img src={home} className=" xs:h-[86px] " alt="" />
                </div>
                <h3 className="text-center font-semibold font-all mt-[12px]">
                  Find Product
                </h3>
              </div>
            </NavLink>

            <NavLink to="exploreService">
              <div className="bg-white shadow-l text-center lg:h-[183px] lg:w-[197px] rounded-md  cursor-pointer  transform hover:scale-105 duration-500 ease-in-out">
                <div className="img">
                  <img src={Explore} className=" xs:h-[86px] " alt="" />
                </div>
                <h3 className="text-center font-semibold font-all mt-[12px]">
                  Explore Service
                </h3>
              </div>
            </NavLink>

            <NavLink to="findjobs">
              <div className=" bg-white shadow-l text-center lg:h-[183px] lg:w-[197px] rounded-md  cursor-pointer  transform hover:scale-105 duration-500 ease-in-out">
                <div className="img ">
                  <img
                    className="rounded-t-lg h-[137px] xs:h-[86px] w-full"
                    src="https://img.freepik.com/free-psd/3d-illustration-people-with-gadget-use-highspeed-internet_1150-65912.jpg?w=740&t=st=1687385575~exp=1687386175~hmac=89e62bb3671514fe3d283d059be93262df0c0d67d6a659852fb232fb88f2c2c4"
                    alt=""
                  />
                </div>
                <h3 className="text-center font-semibold font-all mt-[12px]">
                  Find Jobs
                </h3>
              </div>
            </NavLink>
          </div>

          <div className="flex flex-row gap-4">
            <NavLink to="addProject">
              <div className="bg-white shadow-l text-center lg:h-[183px] lg:w-[197px] rounded-md  cursor-pointer  transform hover:scale-105 duration-500 ease-in-out">
                <div className="img">
                  <img src={Product} alt="" />
                </div>
                <h3 className="text-center font-semibold font-all mt-[12px]">
                  Add Product
                </h3>
              </div>
            </NavLink>

            <NavLink to="/ListProject">
              <div className="bg-white shadow-l text-center lg:h-[183px] lg:w-[197px] rounded-md  cursor-pointer  transform hover:scale-105 duration-500 ease-in-out">
                <div className="img">
                  <img src={ervice} alt="" />
                </div>
                <h3 className="text-center font-semibold font-all mt-[12px]">
                  List Your Service
                </h3>
              </div>
            </NavLink>

            <Link to="/PostJob">
              <div className="bg-white shadow-l text-center lg:h-[183px] lg:w-[197px] rounded-md  cursor-pointer  transform hover:scale-105 duration-500 ease-in-out">
                <div className="img">
                  <img src={Post} alt="" />
                </div>
                <h3 className="text-center font-semibold font-all mt-[12px]">
                  Post Job
                </h3>
              </div>
            </Link>
          </div>
          <div className="flex flex-row gap-[19px]">
            <div className=" text-center lg:h-[auto] lg:w-[406px] rounded-md">
              <div className="img">
                <img src={Member} className="rounded-t-md" alt="" />
              </div>
              <Link to="/register/resellerForm">
                <button className="bg-[#CB2229] text-white rounded-full font-all p-[6px] lg:mt-[1rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out ">
                  Become a reseller and earn
                </button>
              </Link>
            </div>
            <Link to="PostProject">
              <div className="bg-white shadow-l text-center lg:h-[183px] lg:w-[197px] rounded-md  cursor-pointer  transform hover:scale-105 duration-500 ease-in-out">
                <div className="img">
                  <img src={laptop} alt="" />
                </div>
                <h3 className="text-center font-semibold font-all mt-[12px]">
                  Post Project
                </h3>
              </div>
            </Link>
          </div>
        </div>
      </div>
      {/* mobile item  */}

      <div className="mobile-item hidden xs:block mt-[16rem]">
        <div className="flex flex-row justify-start items-center">
          <h1
            className="mt-[8rem] text-2xl font-bold font-all tracking-tight text-gray-900 mb-4 :text-white
"
          >
            Explore INO
          </h1>

          <div className="absolute  lg:top-[3px] z-10 xs:top-[3px] right-[-183px]">
            <button
              onClick={scrollLeft}
              className="lg:w-[1px] relative lg:top-[56px] lg:right-[74.2rem] xxl:right-[65.2rem] lg:h-[4rem] p-2 m-2 rounded-full carousel-bg"
            >
              <FiChevronLeft className="relative lg:right-[7px] text-black" />
            </button>
            <button
              onClick={scrollRight}
              className="lg:w-[1px] relative lg:top-[56px] lg:left-[46px] lg:h-[4rem] p-2 m-2 rounded-full carousel-bg"
            >
              <FiChevronRight className="relative lg:right-[7px] text-black" />
            </button>
          </div>
        </div>

        <div
          className="slider-content overflow-x-auto scroll-smooth w-[43vh]"
          ref={contentRef}
        >
          <div className="flex flex-row items-center gap-4">
            {cards.map((card, index) => (
              <Card
                key={index}
                className="max-w-sm drop dark:bg-white  drop-2 bg-[#dcdcdc3b] h-[37vh] border-flow-bite"
                href={card.link}
              >
                <h5 className="text-2xl font-bold tracking-tight text-gray-900 :text-white">
                  <p className={`whitespace-nowrap font-all`}>{card.title}</p>
                </h5>

                <div
                  className={`border-xy ${card.color} h-[15rem] w-[10rem] relative left-[24px]`}
                >
                  <div className="--img-action">
                    <img
                      src={card.img}
                      className="img-coin image-coin relative h-[121px] top-[39px] left-[1.3rem]"
                      alt=""
                    />
                  </div>
                </div>
              </Card>
            ))}
          </div>
        </div>
        <div class="relative top-[1rem] right-[1rem]">
          <div class="max-w-7xl mx-auto">
            <div class="relative group">
              <div class="absolute -inset-1 bg-gradient-to-r from-yellow-400 to-yellow-400 rounded-lg blur opacity-25 transition duration-1000 group-hover:duration-200"></div>
              <div class="relative px-7 py-6 bg-white ring-1 ring-gray-900/5 rounded-lg leading-none flex items-top justify-start space-x-6">
                <img
                  class="w-auto h-[4rem] text-purple-600 img-coin image-coin"
                  src="https://img.freepik.com/free-vector/online-shopping-concept_52683-63898.jpg?w=1060&t=st=1689796770~exp=1689797370~hmac=eacfc4f8663376d9721474afb28e9c512d8ed342a25d4482016454691f6a0aec"
                  alt=""
                />

                <div class="space-y-2">
                  <p class="text-slate-800">Become a Reseller !</p>
                  <div className="border-xy bg-yellow-400">
                    <span class="block  group-hover:text-slate-800 transition duration-200 text-black font-all  text-[13px] p-[6px] text-center">
                      register youself →
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Services;
