import React, { useState } from 'react';
import { MdCancel } from 'react-icons/md';

const Response = () => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const options = ['Option 1', 'Option 2', 'Option 3', 'Option 4'];

  const handleOptionSelect = (option) => {
    if (!selectedOptions.includes(option)) {
      setSelectedOptions([...selectedOptions, option]);
      setInputValue('');
      setIsDropdownOpen(false); // Close the dropdown after selecting an option
    }
  };

  const handleOptionRemove = (optionToRemove) => {
    const updatedOptions = selectedOptions.filter(
      (option) => option !== optionToRemove
    );
    setSelectedOptions(updatedOptions);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="flex flex-col">
      {/* Input field for multiselect with selected options */}
      <div className="relative inline-block text-left">
        <div className="flex items-center flex-wrap">
          {selectedOptions.map((selectedOption) => (
            <div
              key={selectedOption}
              className="bg-blue-500 text-white py-1 px-2 rounded-full m-1 flex items-center"
            >
              {selectedOption}
              <MdCancel
                className="ml-2 cursor-pointer"
                onClick={() => handleOptionRemove(selectedOption)}
              />
            </div>
          ))}
          <input
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            placeholder={selectedOptions.length ? '' : 'Select options...'}
            onFocus={handleDropdownToggle}
            onClick={handleDropdownToggle}
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
          />
        </div>

        {/* Dropdown with options */}
        {isDropdownOpen && (
          <div
            className="absolute z-10 mt-2 w-64 bg-white border rounded-md shadow-lg fade-in-left"
            style={{ maxHeight: 'max-content', overflowY: 'auto' }}
          >
            <ul>
              {options.map((option) => (
                <li
                  key={option}
                  onClick={() => handleOptionSelect(option)}
                  className="cursor-pointer px-4 py-2 hover:bg-gray-100"
                >
                  {option}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default Response;
